<template>
  <div class="user-detail">
    <Nav></Nav>
    <div class="detail-con mobile-present">
      <!-- <div class="detail-mobile-title">{{ reporterOne.name }}</div> -->
      <div class="detail-mobile-userImg">
        <img class="mobile_img" :src="reporterOne.imgURL" />
      </div>
      <div class="mobile-user-text">
        <div class="mobile-user-title">艺术简介</div>
        <div>
          <span>{{ reporterOne.name }}</span>
          <div v-html="reporterOne.detail"></div>
        </div>
      </div>
      <div class="mobile-user-pic">
        <div class="pic-show">{{ reporterOne.name }}作品赏析</div>
        <div class="pic-list" v-for="post in postpicList" :key="post.id">
          <img class="pic-img" :src="post.imageURL" alt="" />
        </div>
      </div>
    </div>
    <div class="detail-con desktop-present">
      <div class="detail-top">
        <div class="kuan">
          <div class="tip"></div>
        </div>
        <div class="detail-txet">
          <div class="detail-img">
            <div class="m_img" :style="{ backgroundImage: `url(` + reporterOne.imgURL + `)` }"></div>
          </div>
          <div class="detail-comment">
            <span class="name">{{ reporterOne.name }}</span>
            <div v-html="reporterOne.detail"></div>
          </div>
        </div>
      </div>
      <div class="detail-post">
        <div class="big-post">
          <div class="post-list" :style="{ left: '-' + transformDistance + 'rem' }">
            <div class="post-con" v-for="post in postpicList" :key="post.id" :style="{ backgroundImage: `url(` + post.imageURL + `)` }"></div>
          </div>
        </div>
        <div class="post-swiper">
          <div class="swiper">
            <div class="swiper-list" :style="{ left: '-' + transformSwiperDistance + 'rem' }">
              <div
                class="swiper-con"
                v-for="(head, swindex) in postpicList"
                :key="head.id"
                :class="{
                  active: swiperIndex == swindex,
                }"
                @click="onClickSwiper(swindex)"
              >
                <div class="swiper-img" :style="{ backgroundImage: `url(` + head.imageURL + `)` }"></div>
              </div>
            </div>
          </div>
          <div class="swiper-left" @click="onGoleft()">
            <div class="left-icon"></div>
          </div>
          <div class="swiper-right" @click="onGoright()">
            <div class="right-icon"></div>
          </div>
          <div class="swiperPic-left" @click="onGoPicLeft">
            <div class="left-icon"></div>
          </div>
          <div class="swiperPic-right" @click="onGoPicRight">
            <div class="right-icon"></div>
          </div>
        </div>
        <div class="postPicText">{{ swiperIndex + 1 }}/{{ postpicList.length }} {{ picText }}</div>
        <!-- <div class="post-pic">
          <div class="postpic-con">
            <div class="postpic-list" v-for="k in postpicList" :key="k.id">
              <div class="postpic-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onLookPost(reporterOne.headPostDetails[swiperIndex].id)"></div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="gohome">
        <a target="_top" :href="'index.html'">
          <img :src="gohomeimg" alt="" />
        </a>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
import gohomePic from '../assets/replay_img.png'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      reporterOne: {}, //记者的信息
      swiperIndex: 0, // 轮播的index
      transformDistance: 1, // 图片移动的距离
      transformSwiperDistance: 1,
      gohomeimg: gohomePic, // 返回首页的图标
      postpicList: [],
      picText: '',
    }
  },
  created() {
    this.reload()
  },
  methods: {
    async reload() {
      console.log('sjjs', this.$route.query)
      if (this.$route.query.code) {
        RD.userShow()
          .id(this.$route.query.id)
          .oneWithPic(this.$route.query.code)
          .then((data) => {
            data.imgURL = RD.userShow().id(data.id).headPic_URL()
            if (data.headPostDetails.length != 0) {
              data.headPostDetails.forEach((k) => {
                k.imageURL = RD.pic().id(k.id).mImage_URL()
              })
            }

            this.postpicList = data.headPostDetails
            if (this.postpicList.length != 0) {
              this.picText = this.postpicList[0].text
            }

            this.reporterOne = data
          })
      } else {
        RD.userShow()
          .id(this.$route.query.id)
          .oneWithoutType()
          .then((data) => {
            data.imgURL = RD.userShow().id(data.id).headPic_URL()
            data.headPostDetails.forEach((k) => {
              k.imageURL = RD.pic().id(k.frontPicId).mImage_URL()
            })
            this.postpicList = data.headPostPicDetails
            this.reporterOne = data
          })
      }
    },
    // 稿件内的小图
    postpic(index) {
      console.log('ss', index)
      console.log('oppp', this.reporterOne.headPostDetails[index].id)
      RD.post()
        .id(this.reporterOne.headPostDetails[index].id)
        .one()
        .then((info) => {
          console.log('ipp', info)
          info.pics.forEach((j) => {
            j.imgURL = RD.pic().id(j.id).mImage_URL()
          })
          this.postpicList = info.pics
        })
    },
    // 小图的点击
    onClickSwiper(index) {
      var thiz = this
      thiz.transformDistance = index * 110
      this.swiperIndex = index
      this.picText = this.postpicList[index].text
      // this.postpic(index)
    },
    // 图片左移
    onGoleft() {
      console.log('aa', this.swiperIndex)
      if (this.swiperIndex >= 1) {
        this.transformDistance = this.transformDistance - 110
        this.swiperIndex = this.swiperIndex - 1
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1
      } else {
        this.transformDistance = 0
        this.swiperIndex = 0
        this.$message('已经是第一张图片了')
      }
      this.picText = this.postpicList[this.swiperIndex].text
    },
    // 图片右移
    onGoright() {
      var piclength = this.postpicList.length - 1
      if (this.swiperIndex == piclength) {
        this.$message('已经是最后一张了')
        this.swiperIndex = piclength
      } else {
        this.transformDistance = this.transformDistance + 110
        this.transformSwiperDistance = this.transformSwiperDistance + 22.1
        this.swiperIndex = this.swiperIndex + 1
      }
      this.picText = this.postpicList[this.swiperIndex].text
    },
    // 小图左移5张
    onGoPicLeft() {
      console.log('currIndex', this.swiperIndex)
      if (this.swiperIndex == 0) {
        this.swiperIndex = this.swiperIndex + 0
        this.$message('已经是第一张了')
      } else if (this.swiperIndex == 2) {
        this.calculate()
      } else if (this.swiperIndex == 1) {
        this.calculate()
      } else if (this.swiperIndex == 3) {
        this.calculate()
      } else if (this.swiperIndex == 4) {
        this.calculate()
      } else if (this.swiperIndex == 5) {
        this.transformDistance = this.transformDistance - 110 * 5
        this.swiperIndex = 0
        this.transformSwiperDistance = 0
      } else if (this.swiperIndex == 6) {
        this.swiperIndex = this.swiperIndex - 2
        this.transformDistance = this.transformDistance - 110 * (this.swiperIndex - 2)
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * (this.swiperIndex - 2)
      } else if (this.swiperIndex == 7) {
        this.swiperIndex = this.swiperIndex - 3
        this.transformDistance = this.transformDistance - 110 * (this.swiperIndex - 1)
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * (this.swiperIndex - 1)
      } else if (this.swiperIndex == 8) {
        this.swiperIndex = this.swiperIndex - 4
        this.transformDistance = this.transformDistance - 110 * this.swiperIndex
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * this.swiperIndex
      } else if (this.swiperIndex == 9) {
        this.swiperIndex = this.swiperIndex - 5
        this.transformDistance = this.transformDistance - 110 * (this.swiperIndex + 1)
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * (this.swiperIndex + 1)
      } else {
        this.swiperIndex = this.swiperIndex - 5
        this.transformDistance = this.transformDistance - 110 * 5
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * 5
      }
      this.picText = this.postpicList[this.swiperIndex].text
      console.log('now', this.swiperIndex)
    },
    // 小图右移5张
    onGoPicRight() {
      var piclength = this.postpicList.length - 1
      if (this.swiperIndex == piclength) {
        this.$message('已经是最后一张了')
        this.swiperIndex = piclength
      } else if (this.swiperIndex == piclength - 3) {
        this.calculateright()
      } else if (this.swiperIndex == piclength - 2) {
        this.calculateright()
      } else if (this.swiperIndex == piclength - 1) {
        this.calculateright()
      } else if (this.swiperIndex == 0) {
        this.swiperIndex = this.swiperIndex + 5
        this.transformDistance = this.transformDistance + 110 * this.swiperIndex
        this.transformSwiperDistance = this.transformSwiperDistance + 22.1 * this.swiperIndex
      } else {
        this.transformDistance = this.transformDistance + 110 * 5
        this.transformSwiperDistance = this.transformSwiperDistance + 22.1 * 5
        this.swiperIndex = this.swiperIndex + 5
      }
      this.picText = this.postpicList[this.swiperIndex].text
    },
    // 计算距离
    calculate() {
      this.swiperIndex = this.swiperIndex - 1
      this.transformDistance = this.transformDistance - 110
      this.transformSwiperDistance = this.transformSwiperDistance - 22.1
    },
    // 计算距离
    calculateright() {
      this.swiperIndex = this.swiperIndex + 1
      this.transformDistance = this.transformDistance + 110
      this.transformSwiperDistance = this.transformSwiperDistance + 22.1
    },
    // 查看稿件
    onLookPost(postId) {
      this.$router.push({
        path: '/post.html',
        query: { id: postId },
      })
    },
  },
}
</script>

<style scoped lang="less">
.user-detail {
  width: 100%;
  height: auto;
  background-color: #f1f1f1;

  .detail-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    .detail-top {
      height: auto;
      position: relative;
      .kuan {
        position: absolute;
        bottom: -2rem;
        left: 0rem;
        width: 100%;
        border: 0.3rem solid #ff8c00;
        height: calc(100% - 5rem);
        .tip {
          height: calc(100% - 2rem);
          width: 98%;
          position: absolute;
          top: 5%;
          left: 1%;
          margin: 0 auto;
          border: 0.1rem solid #ccc;
        }
      }
      .detail-txet {
        position: relative;
        height: auto;
        width: 50%;
        left: 25%;
        top: 0rem;
        background: #f1f1f1;
        .detail-img {
          height: 12rem;
          width: 60rem;
          .m_img {
            margin: 0.5rem auto;
            width: 10rem;
            height: 10rem;
            border-radius: 5rem;
            background-size: cover;
          }
        }
        .detail-comment {
          height: auto;
          width: 60rem;
          text-align: left;
          font-size: 1.4rem;
          color: #545454;
          text-indent: 2em;
          line-height: 3.6rem;
          padding: 0rem 1rem;
          .name {
            font-size: 1.6rem;
            font-weight: bold;
            margin: 0rem 0.5rem;
          }
        }
      }
    }
    .detail-post {
      height: auto;
      flex-wrap: wrap;
      width: 120rem;
      margin: 4rem auto;
      .big-post {
        height: 70rem;
        width: 109rem;
        margin: 3rem auto;
        overflow: hidden;
        position: relative;
        .post-list {
          height: 70rem;
          position: absolute;
          width: auto;
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          flex-shrink: 0;
          transition: all 800ms ease-in-out;
          .post-con {
            min-width: 110rem;
            height: 100%;
            background: no-repeat 50% / contain;
            background-color: #000;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
          }
        }
      }
      .post-swiper {
        height: 18rem;
        position: relative;
        width: 120rem;
        margin: 0 auto;
        .swiper {
          width: 110rem;
          margin: 0 auto;
          height: 100%;
          overflow: hidden;
          position: relative;
          .swiper-name {
            font-size: 1.3rem;
            height: 3rem;
            line-height: 3rem;
          }
          .swiper-list {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            height: 100%;
            width: auto;
            position: absolute;
            transition: all 800ms ease-in-out;
            .swiper-con {
              width: 21.1rem;
              height: 80%;
              margin-right: 1rem;
              margin-left: 1rem;
              position: relative;
              .swiper-img {
                width: 100%;
                height: 100%;
                background: no-repeat 50% / cover;
              }
            }
            .swiper-con.active {
              border: 0.2rem solid red;
            }
          }
        }

        .swiperPic-left {
          position: absolute;
          width: 4.3rem;
          height: 5rem;
          left: 0.5rem;
          top: 5rem;
          cursor: pointer;
          .left-icon {
            position: absolute;
            top: 1.3rem;
            left: 1.7rem;
            width: 2rem;
            height: 2rem;
            border-top: 0.4rem solid #000;
            border-left: 0.4rem solid #000;
            transform: rotate(-45deg);
          }
        }
        .swiperPic-left:hover {
          background-color: rgba(0, 0, 0, 0.3);
          .left-icon {
            border-top: 0.4rem solid #fff;
            border-left: 0.4rem solid #fff;
          }
        }
        .swiperPic-right {
          position: absolute;
          width: 4.3rem;
          height: 5rem;
          right: 0.5rem;
          top: 5rem;
          cursor: pointer;
          .right-icon {
            position: absolute;
            top: 1.3rem;
            right: 1.7rem;
            width: 2rem;
            height: 2rem;
            border-bottom: 0.4rem solid #000;
            border-right: 0.4rem solid #000;
            transform: rotate(-45deg);
          }
        }
        .swiperPic-right:hover {
          background-color: rgba(0, 0, 0, 0.3);
          .right-icon {
            border-bottom: 0.4rem solid #fff;
            border-right: 0.4rem solid #fff;
          }
        }

        .swiper-left {
          position: absolute;
          width: 5rem;
          height: 5rem;
          left: -1rem;
          top: -39.3rem;
          cursor: pointer;
          .left-icon {
            position: absolute;
            top: 0rem;
            left: 1rem;
            width: 3rem;
            height: 3rem;
            border-top: 0.4rem solid #000;
            border-left: 0.4rem solid #000;
            transform: rotate(-45deg);
          }
        }
        .swiper-right {
          position: absolute;
          right: -1rem;
          top: -39.3rem;
          height: 5rem;
          width: 5rem;
          cursor: pointer;
          .right-icon {
            position: absolute;
            top: 0rem;
            left: 1rem;
            width: 3rem;
            height: 3rem;
            border-bottom: 0.4rem solid #000;
            border-right: 0.4rem solid #000;
            transform: rotate(-45deg);
          }
        }
      }
      .postPicText {
        height: 5rem;
        line-height: 5rem;
        font-size: 1.3rem;
        text-align: left;
        margin-left: 5rem;
      }
      .post-pic {
        height: auto;
        width: 110rem;
        margin: 2rem auto;
        .postpic-name {
          height: 3rem;
          line-height: 3rem;
          font-size: 1.3rem;
        }
        .postpic-con {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .postpic-list {
            width: 20rem;
            height: 15rem;
            .postpic-img {
              height: 12.2rem;
              background: no-repeat 50% / cover;
              cursor: pointer;
            }
            .postpic-text {
              height: 4rem;
              text-align: left;
              font-size: 1.3rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              cursor: pointer;
            }
          }
        }
      }
    }
    .gohome {
      position: fixed;
      bottom: 16%;
      margin-left: -5rem;
      a {
        width: 3rem;
        display: inline-block;
        height: 5rem;
      }
    }
  }
  .detail-con.mobile-present {
    width: 750px;
    .detail-mobile-userImg {
      width: 50%;
      height: 50%;
      border-radius: 189px;
      margin: 0 auto;
      overflow: hidden;
      .mobile_img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / contain;
      }
    }
    .mobile-user-text {
      font-size: 35px;
      margin: 20px;
      letter-spacing: 0.1rem;
      line-height: 80px;
      font-family: Microsoft YaHei;
      text-align: left;
      .mobile-user-title {
        height: 60px;
        line-height: 60px;
      }
      span {
        font-weight: bold;
        margin-right: 20px;
      }
    }
    .mobile-user-pic {
      .pic-show {
        font-size: 35px;
        height: 70px;
        line-height: 70px;
      }
      .pic-list {
        width: 100%;
        margin-bottom: 20px;
        .pic-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }
      }
    }
  }
}
</style>
